
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, getDocs, collection, getDoc, updateDoc, addDoc, where, query, setDoc, deleteDoc, orderBy, serverTimestamp, onSnapshot } from "firebase/firestore";
import { db, storage, auth, } from '../firbase.config.js';
import { createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, getAuth, sendEmailVerification, applyActionCode, reload } from "firebase/auth";
class Auth {

    async getCurrentUser() {
        return localStorage.getItem("user");
    }
    async getNotifyInfo(id, message, title, data) {
        debugger
        const components = query(collection(db, "DNDusers", ), where('_id', '==', id));
        let comps = await getDocs(components);
        let rawData = [];
        for (const key in comps.docs) {
            let data = comps.docs[key].data();
            rawData.push(data);

        }

        
        let obj = {message:message, tokens: rawData[0].tokens, title:title, data:{...data}}
            if(obj.tokens){
                this.notify(obj);
            }
    }
    async notify(body){
        const url = "https://us-central1-spawn-810bf.cloudfunctions.net/notify";
        fetch(url, {
   method: 'POST',
   mode: 'no-cors',
   body: JSON.stringify(body),
   headers:{
       'Conent-Type': 'application/json'
   }
});


}

    async getuser(email, componentList) {
        // //debugger
        //debugger
        let list = componentList.getComponents();
        let IDlist = [];
        for (const key in list) {
            IDlist.push(list[key].getJson()._id)
        }
        let rawData = [];
        const components = await query(collection(db, "DNDusers", "DNDAPP", "components"), where('owner', '==', email), orderBy("date"));
        let comps = await getDocs(components);
        for (const key in comps.docs) {
            let data = comps.docs[key].data()
            if (!IDlist.includes(data._id)) {
                rawData.push(data);
            }
        }
        await componentList.addComponents(rawData, false);
    }

    async getAllTheDataForTheUser(email, componentList, dispatch) {

        let rawData = [];
        const components = await query(collection(db, "DNDusers", "DNDAPP", "components"), where('owner', '==', email), orderBy("date"))

        let comps = await onSnapshot(components, async (querySnapshot) => {


            rawData = [];

            for (const key in querySnapshot.docs) {
                let data = querySnapshot.docs[key].data()
                rawData.push(data);
            }
            await componentList.addComponents(rawData, false);
            let user = await componentList.getComponent('user');

            dispatch({ login: true, register: false, loginPage: false, registerPage: false, user: user, email: email, verified:true });

        })

    };
    async getFollowers(id, componentList) {
        // //debugger
        //debugger
        let list = componentList.getComponents();
        let IDlist = [];
        for (const key in list) {
            IDlist.push(list[key].getJson()._id)
        }
        let rawData = [];
        const components = await query(collection(db, "DNDusers", "DNDAPP", "components"), where('owner', '==', id), orderBy("date"));
        let comps = await getDocs(components);
        for (const key in comps.docs) {
            let data = comps.docs[key].data()
            if (!IDlist.includes(data._id)) {
                rawData.push(data);
            }
        }
        await componentList.addComponents(rawData, false);
        let ar = await componentList.getList("follow", id, "owner");
        return ar;
    }



    // async getComponentsAndUpdata(componentList) {
    //     // //debugger
    //     //debugger

    //     let rawData = [];
    //     const components = await query(collection(db, "DNDusers", "DNDAPP", "components"), where('collection', '!=', ""));
    //     let comps = await getDocs(components);
    //     for (const key in comps.docs) {
    //         let data = comps.docs[key].data()

    //         rawData.push(data);

    //     }


    //     await componentList.addComponents(rawData, false);
    //     let comps1 = componentList.getComponents();
    //     let obj = {update:comps1}
    //     this.dispatch(obj)
    // }

    async getPics(componentList) {

        let rawData = [];

        const components = await query(collection(db, "DNDusers", "DNDAPP", "components"), where('pic', '==', true), orderBy("date"));
        let comps = await getDocs(components);
        for (const key in comps.docs) {
            let data = comps.docs[key].data();

            rawData.push(data);


        }

        await componentList.addComponents(rawData.reverse(), false);
    }


    async getAllThePicsForTheUser(componentList, dispatch) {

        let rawData = [];
        const components = await query(collection(db, "DNDusers", "DNDAPP", "components"), where('pic', '==', true), orderBy("date"));

        let comps = await onSnapshot(components, async (querySnapshot) => {


            rawData = [];

            for (const key in querySnapshot.docs) {
                let data = querySnapshot.docs[key].data()
                rawData.push(data);
            }
            await componentList.addComponents(rawData, false);
            dispatch({ gotPics: true })

        })

    };


    async getComments(componentList, id) {
        //debugger
        let rawData = [];
        let IDlist = []
        let comments = componentList.getList("comment");
        for (const key in comments) {
            IDlist.push(comments[key].getJson()._id)
        }
        const components = await query(collection(db, "DNDusers", "DNDAPP", "components"), where('picOwner', '==', id),);
        let comps = await getDocs(components);
        for (const key in comps.docs) {
            let data = comps.docs[key].data();
            if (!IDlist.includes(data._id)) {
                rawData.push(data);
            }
        }

        await componentList.addComponents(rawData, false);
    }
    async getPicOwner(componentList, id) {
        //debugger
        let rawData = [];
        let IDlist = [];
        let users = componentList.getList("user");
        for (const key in users) {
            IDlist.push(users[key].getJson()._id)
        }
        const components = await query(collection(db, "DNDusers", "DNDAPP", "components"), where('_id', '==', id));
        let comps = await getDocs(components);
        for (const key in comps.docs) {
            let data = comps.docs[key].data();
            if (!IDlist.includes(data._id)) {
                rawData.push(data);
            }
        }
        await componentList.addComponents(rawData, false);
        let picOwner = componentList.getComponent("user", id, "_id")
        return picOwner
    }
    async getFollower(componentList, id) {
        //debugger
        let rawData = [];
        let IDlist = [];
        let follow = componentList.getList("follow");
        for (const key in follow) {
            IDlist.push(follow[key].getJson()._id);
        }
        const components = await query(collection(db, "DNDusers", "DNDAPP", "components"), where('followID', '==', id));
        let comps = await getDocs(components);
        for (const key in comps.docs) {
            let data = comps.docs[key].data();
            if (!IDlist.includes(data._id)) {
                rawData.push(data);
            }
        }
        await componentList.addComponents(rawData, false);
        let following = componentList.getComponent("follow", id, "followID");
        return following
    }

    async verifyUser(oob,) {
        await applyActionCode(auth, oob)
            .then(() => {
                console.log("success");
                // handle success here
            }).catch(err => {
                console.log(err);
                //handle failure here
            });
            console.log(auth.currentUser);
        reload(auth.currentUser).then(() => {
            console.log(auth.currentUser.emailVerified)
        })


    }


    async login(email, password, componentList, dispatch) {
        let user;
        await signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                user = userCredential.user;
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            });
        if (user) {
            if (user.emailVerified) {
                let saveUser = user
                await localStorage.setItem("user", JSON.stringify(saveUser));

                 window.location.href="/"
                // await this.getuser(email, componentList);
                // await this.getAllTheDataForTheUser(email, componentList, dispatch);

                // user = await componentList.getComponent('user');
            }
            else {
                user = false;
                var verifyUser = auth.currentUser;
                await sendEmailVerification(verifyUser).catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorMessage);
                    console.log(errorCode)
                })
            }

        }
        return user;
    }

    async register(email, password, addToCache) {

        let user;
        await createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
            user = userCredential.user;
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

        })
        if (user) {

            var verifyUser = auth.currentUser;
            await sendEmailVerification(verifyUser).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorMessage);
                console.log(errorCode)
            })
        }
        return user
        //     if(addToCache){
        //         localStorage.setItem("user", JSON.stringify(user));

        //     }

        // return user;
    }
    async logout() {
        await localStorage.clear();
        let logouser;
        await onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                logouser = user.uid;
                // ...
            }
        })
        if (logouser) {
            await signOut(auth);

        }
        window.location.reload();
    }
    async uploadPics(pic, name) {
        const storageRef = ref(storage, name);
        await uploadBytes(storageRef, pic).then((snapshot) => {
            console.log('Uploaded a blob or file!');
        });
    }
    async downloadPics(name) {
        let src;
        await getDownloadURL(ref(storage, name)).then((url) => {

            src = url;
        })
        return src;
    }
    deletePics(name) {
        //debugger
        const delRef = ref(storage, name);
        // Delete the file
        deleteObject(delRef).then(() => {
            // File deleted successfully
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    sendForgotPasswordChange(email) {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                // ..
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
    }



    /**
         * 
         * @param {*} role 
         * @param {*} id 
         * @param {*} changeData 
         * @returns change any data I want.
         */
    async dispatch(obj, email) {

        //debugger
        for (const key in obj) {
            let operate = obj[key];
            for (let i = 0; i < operate.length; i++) {
                const delay = ms => new Promise(res => setTimeout(res, ms));
                await delay(1000);
                let component = key !== "del" ? operate[i].getJson() : operate[i];
                switch (key) {
                    case "add":
                        component.collection = email;
                        component.date = await serverTimestamp();
                        await setDoc(doc(db, 'DNDusers', "DNDAPP", 'components', component._id), component);
                        break;
                    case "del":
                        await deleteDoc(doc(db, 'DNDusers', "DNDAPP", 'components', component));
                        break;
                    case "update":
                        component.date = await serverTimestamp();
                        await updateDoc(doc(db, 'DNDusers', "DNDAPP", 'components', component._id), component);
                        break;
                }

            }
        }
    }


    // user(){

    // }
    // async getuser(componentList){
    //     let user = {};
    //     let components = [
    //         {name:"jared", email:"jared@gmail.com", type:"user", _id: "1"}, 
    //         {name: "mummy", pic: Mummy, type:"monsters",_id: "2"}, 
    //         {name: "bongobongo", pic: Bongobongo, type:"monsters",_id: "3"},
    //         {name: "celestial", pic: Celestial, type:"heroes", _id: "4"},
    //         {name: "dragon", pic: Dragon, type:"statblocks",_id: "5"}
    //     ];
    //     await componentList.addComponents(components);
    //     user.components=componentList.getComponents();
    //     return user;
    // }
}
export default new Auth();