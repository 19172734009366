export default class BaseClass {
    operationsFactory;
    json;
    constructor(oppsFactory){
        this.setJson=this.setJson.bind(this);
        this.getJson=this.getJson.bind(this);
        this.getOperationsFactory=this.getOperationsFactory.bind(this);
        this.setCompState=this.setCompState.bind(this);
        this.operationsFactory=oppsFactory;
    }

    getOperationsFactory(){
        return this.operationsFactory;
    }
    setJson(json){
        this.json=json;
    }
    getJson(){
        return this.json
    }

    /**
    *
    * @param obj
    * @param callBack
    * Works exactly like setState in react only I include a function for a callback if needed
    */
   setCompState(obj, callBack){
   this.json={...this.json, ...obj};
   if(callBack){
       callBack();
   }
   }
}