import React, { Component } from 'react';
import "../App.css"

export default class Registered extends Component {
    constructor(props){
        super(props);
     
        this.state={
            opacity: 1,
        }
    }
 
    componentDidMount() {
        this.interval = setInterval(() => {
          this.setState((prevState) => {
            return {
              opacity: prevState.opacity - 0.11,
            };
          });
          if (this.state.opacity <= 0) {
            clearInterval(this.interval);
          }
        }, 100);
      }
    
      componentWillUnmount() {
        clearInterval(this.interval);
      }
   
render(){
    const borderColor =  `rgba(168, 3, 3, ${this.state.opacity})`;

        return(
            <>
            
            <div style={{
                zIndex:300, 
                position:"absolute", justifyContent:"center", alignSelf:"center", alignContent:"center",
                width:"96vw", 
                height:"80vh", 
                marginTop:"15vh", 
                padding:"2.2vmax", 
                background:"#FBFFFF",
                fontFamily: 'InriaBold',
                fontSize: "1vmax",
                userSelect:"none",
                
                borderRadius:"1.6vmax"
                }} >
                 Thanks for registering!
                 <div style={{
                
                marginTop:"5vh", 
                userSelect:"none",
                fontFamily: 'InriaBold',
                fontSize: "1vmax", padding:"1vmax",
                borderStyle:"solid",
                borderColor:borderColor,
                borderRadius:"8px"
                }} >
                 An email was sent to you for verification. Please verify to <a href="/">login.</a>
                 
                 </div>
                 </div>
            
                 </>
             )
            }
            
    

}
