import React, { Component } from 'react';
import auth from '../services/auth';


export default class Verify extends Component {
    constructor(props){
        super(props);
        this.state={
            opacity: 1,
        };
    }
    async componentDidMount(){
        //debugger
        this.interval = setInterval(() => {
            this.setState((prevState) => {
              return {
                opacity: prevState.opacity - 0.11,
              };
            });
            if (this.state.opacity <= 0) {
              clearInterval(this.interval);
            }
          }, 100);
        let url = window.location.search;
        const urlParams = new URLSearchParams(url);
        const oob = urlParams.get('oobCode')
        await auth.verifyUser(oob);
        this.prop.app.dispatch({verified:true});
        
    }
   
    
      componentWillUnmount() {
        clearInterval(this.interval);
      }
   
render(){
    const borderColor =  `rgba(168, 3, 3, ${this.state.opacity})`;
    const textColor = `rgba(208, 208, 208, d`+ this.state.opacity +`)`;
        

        return(
            <>
            <div style={{zIndex: 300,
            position: 'absolute',
            width: '40vw',
            height: 'fit-content',
            paddingLeft: '1vmax',
            userSelect: 'none',
            fontFamily: 'InriaBold',
            fontSize: '1vmax',
            borderStyle: 'solid',
            color: textColor,
            borderColor: borderColor,
            borderRadius: '8px',
            marginTop: '15vh',
            marginLeft: '2vw',
            marginBottom: '1vh',
            background: '#00000000',
            transition: 'border-color 0.1s linear',}}>
                Your account has been verified.
                </div>
            
                 </>
             )
            }
};

