import React, { Component, useEffect } from 'react';
import authService from '../services/auth';
import Registers from '../pics/STATSPAWN.png';
import whatisthis from '../pics/quest.svg';
import Compressor from 'compressorjs';
import '../services/ImageTooltip.css';

import ParentFormComponent from '../componentListNPM/parentFormComponent';


export default class Register extends Component {
    constructor(props){
        super(props);
        this.handleSubmission= this.handleSubmission.bind(this);
        this.handleChange=this.handleChange.bind(this);
        this.changeHandler=this.changeHandler.bind(this);
        this.wrapperRef = React.createRef();

        this.setWrapperRef = this.setWrapperRef;
        this.state={
            selectedFile: undefined,
            path: undefined,
            email: "",
            password: "",
            firstName:"",
            lastName:"",
            socialHandle:"",
            bio:"",
            website:"",
            socialHandle:"",
            readEULA: false,
            showTooltip3: false, showTooltip2: false, showTooltip: false, 
        };
    }

    changeHandler = async (event) => {
        let path = "images/" + event.target.files[0].name;
        await fetch(event.target.files[0])
            .then(function (response) {
                return response.blob()
            })
            .then(function (blob) {
                // here the image is a blob
            });
        this.setState({
            selectedFile: event.target.files[0],
            path: path,
            changed: true,
            pic: URL.createObjectURL(event.target.files[0])
        })
    };
	handleChange = async (event) => {
        let { name, value } = event.target;
        this.setState({
            [name]: value
        })
        
	};


	async handleSubmission()  {
        if(this.state.firstName===""||this.state.firstName===undefined){
            this.setState({
                message:"Please fill out your first name"
                
            })
            return;
        }
        if(this.state.lastName===""||this.state.lastName===undefined){
            this.setState({
                message:"Please fill out your last name"
                
            })
            return;
        }
        if(this.state.spawnerHandle===""||this.state.spawnerHandle===undefined){
            this.setState({
                message:"Please fill out a spawner handle"
                
            })
            return;
        }
       
        
        
        if(this.state.email===""||this.state.email===undefined){
            this.setState({
                message:"Please fill out your email"
                
            })
            return;
        }
        if(this.state.password===""||this.state.password===undefined){
            this.setState({
                message:"Please enter a password"
                
            })
            return;
        }


             ///If they havent checked box return error message
             if(this.state.readEULA===false){
                this.setState({
                    message:"You need to read and agree to the End User License Agreement to proceed."
                    
                })
                return;
            }
        if(this.state.selectedFile && this.state.path){
            await authService.uploadPics(this.state.selectedFile, this.state.path, this.props.app.dispatch);

        }
        
        let user =await authService.register(this.state.email.toLowerCase(), this.state.password);
        //debugger
        if(user){
            await this.props.app.state.currentComponent?.getOperationsFactory().componentDispatch({addemail:this.state.email.toLowerCase(), 
                addspawnerHandle:this.state.spawnerHandle, addfirstName:this.state.firstName, 
                addlastName:this.state.lastName, addbio:this.state.bio, addwebsite:this.state.website, 
                addsocialHandle: this.state.socialHandle, add_id:this.state.email, addowner:this.state.email,
                addhash:this.state.spawnerHandle + Math.floor(Math.random() * 10000)});
                if(this.state.path){
                    await this.props.app.state.currentComponent?.getPicSrc(this.state.path);

                }
            await this.props.app.dispatch({ email: this.state.email})
            await this.props.app.state.currentComponent?.getOperationsFactory().run();
            this.setState({registered:true, });
            this.props.app.dispatch({setRegister:true});
            
            // this.props.app.dispatch({login:true, register:false, loginPage:false, registerPage:false, user:this.props.app.state.currentComponent})
        }
        else{
            this.setState({
                message:"Either this email is already in use. The email does not exist or your password is not secure enough."
            })
        }
        
        
	};

    
  handleMouseOver = () => {
    this.setState({ showTooltip: true });
  };

  handleMouseLeave = () => {
    this.setState({ showTooltip: false });
  };

  handleMouseOver2 = () => {
    this.setState({ showTooltip2: true });
  };

  handleMouseLeave2 = () => {
    this.setState({ showTooltip2: false });
  };

  handleMouseOver3 = () => {
    this.setState({ showTooltip3: true });
  };

  handleMouseLeave3 = () => {
    this.setState({ showTooltip3: false });
  };

    render(){
        const { showTooltip, showTooltip2, showTooltip3} = this.state;
        
     
        let app = this.props.app;
        let state = app.state;
        
        let styles =state.styles;
        let dispatch = app.dispatch;
        let component = state.currentComponent;
        let compJson = component?.getJson();
        let opps = component?.getOperationsFactory();
        let key =compJson?.collection? "update": "add";

        return(  
                    <div style={{ display:"flex", flexDirection:"column", alignItems:"center", overflow:"scroll",
                    width:"100%",  borderRadius:"10px, 10px, 10px, 10px", background:"white", opacity:"1", height:"90vh", padding:"1.8vh"}}>
                                                 <div style={{display:"flex", color:styles.colors.Red2,
                         flexDirection:"column",alignItems:"center", fontFamily:styles.fonts.fontNormal, fontSize: "1.0vmax",
                         marginBottom: "4.2vh", cursor:"pointer", textDecoration:"underline 1px #888888"}} onClick={dispatch.bind(this, {registerPage:false})}> Go Back to Login</div>
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"fit-content", height:"fit-content",
                        borderRadius:"2vw", marginTop: styles.margins.marginMediumH, background:styles.colors.Grey2 + "95", padding:"1vw", paddingRight:"4.5vw", paddingLeft:"4.5vw" }}>
                            <img 
                            style={{marginTop:"-3vw",width:"5vw", height:"fit-content", objectFit:"scale-down", filter:"saturate(90% ) drop-shadow(0px 0px 4px "+styles.colors.Red2+")"}} 
                            src={Registers}/>
                        <div 
                        style={{display:"flex", flexDirection:"column",alignItems:"center", fontFamily: styles.fonts.fontTitle, marginTop: "-1vh", fontSize: styles.fonts.fontHeader5,}}>
                           
                            Create Spawner Account
                             
                            </div>
                            <div style={{color:styles.colors.Red2, fontSize:"2.2vmin", marginBottom:"2vmin", fontFamily:styles.fonts.fontNormal}}>* denotes a required field</div>

                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", }}>
                            <img style={{width:"9vh", height:"9vh", objectFit:"cover", borderRadius:"50%",}}
                       
                         src = {this.state.pic}/>
                            <label htmlFor="lastName"><div style={{fontFamily: styles.fonts.fontNormal, fontSize: styles.fonts.fontHeader2, marginBottom:"1vh"}}>Avatar</div></label>
                            <input accept="image/png, image/gif, image/jpeg" style={{ cursor: "pointer", width: "10vw",
                        height: "3vh",  }} type="file" name="file" onChange={this.changeHandler}  />
                        
                                                </div>  
                       
    <div style={{marginBottom:"2vh",}}>
        
                        <div style={{display:"flex", flexDirection:"column",alignItems:"center", marginTop: styles.margins.marginMediumH}} className="form-group">
                            <label
                            htmlFor="lastName">
                                <div style={{display:"flex", flexDirection:"row", fontFamily: styles.fonts.fontNormal, fontSize: styles.fonts.fontHeader1,
                                marginBottom:".8vh",}}>
                                    First Name
                                    <div style={{color:styles.colors.Red2, fontSize:"2.2vmin"}}>*</div>
                                    </div>
                                    </label>
                            <input style ={{...styles.inputStyle, width:"18vw" }} 
                            type="text" className="form-control" id="last"   onChange={this.handleChange} name="firstName"/>
                        </div> 


                        <div style={{display:"flex", flexDirection:"column",alignItems:"center", marginTop: styles.margins.marginMediumH}} className="form-group">
                            <label htmlFor="lastName">
                            <div style={{display:"flex", flexDirection:"row", fontFamily: styles.fonts.fontNormal, fontSize: styles.fonts.fontHeader1,
                                marginBottom:".8vh",}}>
                                    Last Name
                                    <div style={{color:styles.colors.Red2, fontSize:"2.2vmin"}}>*</div>
                                    </div></label>
                            <input style ={{...styles.inputStyle}} 
                            type="text" className="form-control" id="last"   onChange={this.handleChange} name="lastName"/>
                        </div>  


                        <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginTop: styles.margins.marginMediumH}} className="form-group">
                            <label htmlFor="lastName">
                            <div style={{display:"flex", flexDirection:"row", fontFamily: styles.fonts.fontNormal, fontSize: styles.fonts.fontHeader1,
                                marginBottom:".8vh",}}>
                                    Spawner Handle
                                    <div style={{color:styles.colors.Red2, fontSize:"2.2vmin"}}>*</div>
                                                    <div className="image-container"
                                                    onMouseOver={this.handleMouseOver}
                                                    onMouseLeave={this.handleMouseLeave} style={{ position: "relative", display: "inline-block" }}>
                                                    <img style={{width:"2vmin", marginLeft:".4vmin", cursor:"help"}} src={whatisthis}/>                                             
                                                    
                                                    <div
                                                        className={`tooltip ${showTooltip ? "visible" : ""}`}
                                                    >Your handle is the name that is publicly visible to the Spawn community. You cannot impersonate real people or legal entities. </div>
                                                    </div>
                                    </div></label>
                            <input style ={{...styles.inputStyle,}} 
                            type="text" className="form-control" id="last"  minLength="3" maxLength="27" onChange={this.handleChange} name="spawnerHandle"/>
                            
                        </div>


                        <div style={{display:"flex", flexDirection:"column",alignItems:"center", marginTop: styles.margins.marginMediumH}} className="form-group">
                            <label htmlFor="lastName">
                                
                                <div style={{fontFamily: styles.fonts.fontNormal, fontSize: styles.fonts.fontHeader1,
                                marginBottom:".8vh", }}>
                                    Bio
                                    </div></label>
                            <textarea rows="3" style ={{...styles.inputStyle, height:"8vh" }} maxLength="1400"
                            type="text" className="form-control" id="last"   onChange={this.handleChange} name="bio"/>
        
                        </div>


                        <div style={{display:"flex", flexDirection:"column",alignItems:"center", marginTop: styles.margins.marginMediumH}} className="form-group">
                            <label htmlFor="lastName">
                                <div style={{fontFamily: styles.fonts.fontNormal, fontSize: styles.fonts.fontHeader1,
                                marginBottom:".8vh",}}>
                                    Your Website</div></label>
                            <input style ={{...styles.inputStyle, }} 
                            type="text" className="form-control" id="last"   onChange={this.handleChange} name="website"/>
                        </div>


                        <div style={{display:"flex", flexDirection:"column",alignItems:"center", marginTop: styles.margins.marginMediumH}} className="form-group">
                            <label htmlFor="lastName">
                                <div style={{ display:"flex", flexDirection:"row",fontFamily: styles.fonts.fontNormal, fontSize: styles.fonts.fontHeader1,
                                marginBottom:".8vh",}}>
                                    Social Link URL
                                    <div className="image-container"
                                                    onMouseOver={this.handleMouseOver2}
                                                    onMouseLeave={this.handleMouseLeave2} style={{ position: "relative", display: "inline-block" }}>
                                                    <img style={{width:"2vmin", marginLeft:".4vmin", cursor:"help"}} src={whatisthis}/>                                             
                                                    
                                                    <div
                                                        className={`tooltip ${showTooltip2 ? "visible" : ""}`}
                                                    >Add a link to your twitter, discord or other social media account. </div>
                                                    </div>
                                   </div>                                   
                                   </label>
                            <input style ={{...styles.inputStyle}} 
                            type="text" className="form-control" id="last"   onChange={this.handleChange} name="socialHandle"/>
                        </div>
                        
                     <div style={{display:"flex", flexDirection:"column",alignItems:"center", marginTop: styles.margins.marginMediumH}} className="form-group">
                            <label htmlFor="lastName">
                            <div style={{display:"flex", flexDirection:"row", fontFamily: styles.fonts.fontNormal, fontSize: styles.fonts.fontHeader1,
                                marginBottom:".8vh",}}>
                                    Email Address
                                    <div style={{color:styles.colors.Red2, fontSize:"2.2vmin"}}>*</div>
                                    <div className="image-container"
                                                    onMouseOver={this.handleMouseOver3}
                                                    onMouseLeave={this.handleMouseLeave3} style={{ position: "relative", display: "inline-block" }}>
                                                    <img style={{width:"2vmin", marginLeft:".4vmin", cursor:"help"}} src={whatisthis}/>                                             
                                                    
                                                    <div
                                                        className={`tooltip ${showTooltip3 ? "visible" : ""}`}
                                                    >Required for account creation and verification. After registration we'll send you an email to verify your account. </div>
                                                    </div>
                                    </div></label>
                            <input style ={{...styles.inputStyle}} 
                            type="text" className="form-control" id="last" minLength={6}  onChange={this.handleChange} name="email"/>
                        </div>


                        <div style={{display:"flex", flexDirection:"column",alignItems:"center", marginTop: styles.margins.marginMediumH}} className="form-group">
                            <label htmlFor="lastName">
                            <div style={{display:"flex", flexDirection:"row", fontFamily: styles.fonts.fontNormal, fontSize: styles.fonts.fontHeader1,
                                marginBottom:".8vh",}}>
                                    Password
                                    <div style={{color:styles.colors.Red2, fontSize:"2.2vmin"}}>*</div>
                                    </div></label>
                            <input style ={{...styles.inputStyle}} 
                            type="password" className="form-control" id="last" onChange={this.handleChange} name="password"/>
                        </div>

                        </div>
{/* THE EULA PART */}
<div style={{display:"flex", flexDirection:"row", fontSize:"1.5vmin", marginTop:"2vmin", justifyContent:"center", width:"34vw"}}>
    {/* CHECKBOX*/}
    <div>
                            
        <ParentFormComponent name="EULA" obj={component} type="checkbox" func={(user,value)=>{
            //debugger
            this.setState({readEULA:value});
            //debugger
            user[0].setCompState({EULA:value});
            }}/>
    </div> 
<div style={{display:"flex", flexDirection:"column", fontSize:"1.5vmin",}}>
                        <div style={{display:"flex", flexDirection:"row", fontFamily:styles.fonts.fontNormal, fontSize:"1.6vmin"}}>
                        I have read and agree to the terms and conditions.
                        <div style={{color:styles.colors.Red2, fontSize:"2.2vmin"}}>*</div></div>
                        <a href= {"/eula"} target="_blank" rel="noopener noreferrer"
                        style={{fontFamily:styles.fonts.fontNormal, fontSize:"1.5vmin", marginTop:"3px", textDecoration:"underline 1px #888888", color:styles.colors.colorLink, alignSelf:"flex-start"}}>
                        Read the Terms and Conditions.
                        </a>
                        
</div></div>
{/* BUTTON & END */}
                        <div>
                         <button style={{...styles.buttons.buttonFollow, height:"3.5vh", marginTop: "3vh", marginBottom:"2vh", 
                         fontSize:"1.1vmax", borderWidth:"2px", borderColor:"styles.colors.Red2"}} 
                         class= "btn" onClick={this.handleSubmission}>
                            Register</button>
                         

                     </div>
                     <div style={{color:"red"}}>{this.state.message}</div>
                     </div>
                 </div>
             )
        }
	
}




