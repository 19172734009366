class Picservice{
    randomizepics(pics, type){
        
        // let newPics=[];
        // for(const key in pics){
        //     let newtype= pics[key].getJson().type
        //     if(newtype===type && newtype!=="user"){
        //         newPics.push(pics[key]);
        //     }
        // }
        
        // return newPics[Math.floor(Math.random() * newPics.length)];
    }

    savepic(state, pic){
        // let array = state.recentpics;
        // array.push(pic);
        //     return array;
    }



}
export default new Picservice